@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500&display=swap");

body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #6f6c7f;
  line-height: 36px;
  background: #fff;
  overflow-x: hidden !important;
  font-weight: 400;
  box-sizing: border-box;
}

h1 {
  font-size: 50px;
  line-height: 55px;
}

h2 {
  font-size: 40px;
  line-height: 55px;
}
h3 {
  font-size: 30px;
}
h4 {
  font-size: 26px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 16px;
}

p {
  font-family: "Poppins", sans-serif;
  line-height: 30px;
  font-weight: 400;
  font-size: 16px;
}

/* a {
  color: #3f51b5;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
} */
/* a:hover {
  color: #3f51b5;
} */
a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
::-moz-selection {
  background: #31333e;
  color: #fff;
  text-shadow: none;
}
::selection {
  background: #31333e;
  color: #fff;
  text-shadow: none;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none !important;
}
button[type="submit"] {
  cursor: pointer;
  border: none;
}
textarea {
  resize: none;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(66, 66, 66, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(66, 66, 66, 0.3);
  background-color: rgb(121, 121, 121);
}

@media screen and (max-width: 600px) {
  body {
    text-align: center;
  }
}
